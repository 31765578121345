import { useApiClient } from '@/api-client/okta-api-client-controller';
import { PaginatedResponse } from '@/types';

interface UploadHistoryParams {
    status?:
        | 'pending'
        | 'in_process'
        | 'processing_lock'
        | 'error'
        | 'processing_error'
        | 'scheduled'
        | 'done'
        | 'sending_lock'
        | 'sending_fail'
        | 'sent';
    dateFrom?: string;
    dateTo?: string;
    page?: number;
    perPage?: number;
    sortBy?: 'date' | 'status' | 'version' | 'correctionsNumber' | 'retriesNumber' | 'documentName';
    sortDir?: 'asc' | 'desc';
}

interface UploadHistoryRequest {
    fileUuid: string;
}

interface UploadHistory {
    id: number;
    status:
        | 'pending'
        | 'in_process'
        | 'processing_lock'
        | 'error'
        | 'processing_error'
        | 'scheduled'
        | 'done'
        | 'sending_lock'
        | 'sending_fail'
        | 'sent';
    correctionsNumber: number;
    retriesNumber: number;
    errorsNumber: number;
    validationErrors: (string | null)[];
    scheduledTimeToPush: string;
    configurationResource: {
        id: number;
        displayName: string;
        uuid: string;
        status: string;
        activeVersion: string;
        categoryResource: Record<string, unknown>;
        ownerEmail: string;
    };
    versionResource: {
        version: string;
        description: string;
    };
    fileResource: {
        createdAt: string;
        uuid: string;
        originalName: string;
        extension: string;
    };
    userResource: {
        id: number;
        firstName: string;
        lastName: string;
        locale: string;
        timeZone: string;
    };
    schemaResource: {
        columns: Record<string, unknown>[];
    };
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useAdminUploadHistoryApi = () => {
    const { apiClient } = useApiClient();

    const buildQueryString = (params: Record<string, unknown>): string => {
        const filteredParams = Object.entries(params)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .filter(([_, value]) => value !== undefined && value !== null && value !== '')
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

        const queryString = Object.entries(filteredParams)
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join('&');

        return queryString;
    };

    const createUploadHistory = async (
        configurationId: number,
        requestBody: UploadHistoryRequest,
    ): Promise<UploadHistory> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<UploadHistory>(
            `/api/v1/admin/configuration/${configurationId}/upload-history/create`,
            { method: 'POST', body: JSON.stringify(requestBody), headers: { 'Content-Type': 'application/json' } },
            'createUploadHistory',
        );
    };

    const getUploadHistoryListById = async (
        configurationId: number,
        params: UploadHistoryParams,
    ): Promise<PaginatedResponse<UploadHistory>> => {
        const queryString = buildQueryString(params);
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<PaginatedResponse<UploadHistory>>(
            `/api/v1/admin/configuration/${configurationId}/upload-history/list?${queryString}`,
            { method: 'GET' },
            'getUploadHistoryListById',
        );
    };

    const getUploadHistory = async (configurationId: number, uploadHistoryId: number): Promise<UploadHistory> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<UploadHistory>(
            `/api/v1/admin/configuration/${configurationId}/upload-history/${uploadHistoryId}/show`,
            { method: 'GET' },
            'getUploadHistory',
        );
    };

    const getUploadHistoryDataList = async (
        uploadHistoryId: number,
        filter: string = 'all',
        cursor: number | undefined,
    ): Promise<UploadHistoryDataList> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }

        const url = cursor
            ? `/api/v1/admin/configuration/upload-history/${uploadHistoryId}/data?cursor=${cursor}&filter=${filter}`
            : `/api/v1/admin/configuration/upload-history/${uploadHistoryId}/data?filter=${filter}`;
        return apiClient.fetch<UploadHistoryDataList>(url, { method: 'GET' }, 'getUploadHistoryDataList');
    };

    const updateUploadHistoryData = async (
        uploadHistoryId: number,
        cellId: number,
        value: string,
    ): Promise<UploadHistory> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<UploadHistory>(
            `/api/v1/admin/configuration/upload-history/${uploadHistoryId}/data/${cellId}/update`,
            { method: 'PUT', body: JSON.stringify({ value }), headers: { 'Content-Type': 'application/json' } },
            'updateUploadHistoryData',
        );
    };

    const getUploadHistoryManuallySend = async (uploadHistoryId: number): Promise<void> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<void>(
            `/api/v1/admin/configuration/upload-history/${uploadHistoryId}/manually-send`,
            { method: 'GET' },
            'getUploadHistoryManuallySend',
        );
    };

    const patchConfigurationAdminUserUploadHistoryAutomaticSendingPause = async (
        uploadHistoryId: string,
    ): Promise<UploadHistory> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<UploadHistory>(
            `/api/v1/admin/configuration/upload-history/${uploadHistoryId}/pause`,
            { method: 'PATCH' },
            'patchConfigurationAdminUserUploadHistoryAutomaticSendingPause',
        );
    };

    const patchConfigurationAdminUserUploadHistoryAutomaticSendingResume = async (
        uploadHistoryId: string,
    ): Promise<UploadHistory> => {
        if (!apiClient) {
            throw new Error('API Client not available');
        }
        return apiClient.fetch<UploadHistory>(
            `/api/v1/admin/configuration/upload-history/${uploadHistoryId}/resume`,
            { method: 'PATCH' },
            'patchConfigurationAdminUserUploadHistoryAutomaticSendingResume',
        );
    };

    return {
        createUploadHistory,
        getUploadHistoryListById,
        getUploadHistory,
        getUploadHistoryDataList,
        updateUploadHistoryData,
        getUploadHistoryManuallySend,
        patchConfigurationAdminUserUploadHistoryAutomaticSendingPause,
        patchConfigurationAdminUserUploadHistoryAutomaticSendingResume,
    };
};
