import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@/store';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    TableSortLabel,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    TextField,
    MenuItem,
    RadioGroup,
    FormControlLabel,
    Radio,
    CircularProgress,
} from '@mui/material';
import debounce from 'lodash/debounce';
import {
    getConfigurationsCategoriesListFromState,
    setConfigurationsCategoriesFullList,
    setConfigurationsCategoriesList,
} from '@/store/configurationsSlice';
import { useConfigurationsApi } from '@/api-client/configurations-api';
import { CONFIGURATIONS_CATEGORY_STATUS } from '@/types';
import { useSnackbar } from 'notistack';
import CustomButton from '@/components/custom-button';
import CustomTextField from '@/components/custom-text-field';
import CustomSelect from '@/components/custom-select';
import ClearIcon from '@mui/icons-material/Clear';

const ConfigurationsCategories: React.FC = () => {
    const {
        getConfigurationsCategoriesList,
        createConfigurationCategory,
        updateConfigurationCategory,
        archiveConfigurationCategory,
        restoreConfigurationCategory,
    } = useConfigurationsApi();
    const dispatch: AppDispatch = useDispatch();
    const configurationsList = useSelector(getConfigurationsCategoriesListFromState);
    const { enqueueSnackbar } = useSnackbar();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [categoryStatus, setCategoryStatus] = useState<string>('');
    const [sortBy, setSortBy] = useState<string>('id');
    const [sortDir, setSortDir] = useState<'asc' | 'desc'>('desc');
    const [open, setOpen] = useState(false);
    const [openArchive, setArchiveOpen] = useState(false);
    const [openRestore, setRestoreOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [teamName, setTeamName] = useState('');
    const [editTeamId, setEditTeamId] = useState<number | null>(null);
    const [valueRadio, setValueRadio] = React.useState(false);
    const [loading, setLoading] = useState(true);

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setValueRadio((event.target as HTMLInputElement).value);
    };

    const handleChangePage = (event: unknown, newPage: number): void => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchTerm(event.target.value);
    };

    const handleCategoryStatusChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
        setCategoryStatus(event.target.value as string);
    };

    const handleSortChange = (property: string): void => {
        const isAsc = sortBy === property && sortDir === 'asc';
        setSortDir(isAsc ? 'desc' : 'asc');
        setSortBy(property);
    };

    const fetchConfigurationsList = async (
        keyword: string,
        categoryStatus: string,
        currentPage: number,
        currentRowsPerPage: number,
        currentSortBy: string,
        currentSortDir: string,
    ): Promise<void> => {
        setLoading(true);
        try {
            const list = await getConfigurationsCategoriesList({
                keyword,
                status: categoryStatus,
                page: currentPage,
                perPage: currentRowsPerPage,
                sortBy: currentSortBy,
                sortDir: currentSortDir,
            });
            dispatch(setConfigurationsCategoriesList(list));
        } catch (error) {
            if (error?.details?.violations) {
                error.details.violations.violations.forEach((violation: unknown) => {
                    enqueueSnackbar(`${violation.propertyPath}: ${violation.title}`, {
                        variant: 'error',
                        preventDuplicate: true,
                    });
                });
            } else {
                enqueueSnackbar(`${error?.title || 'Error occurred'}`, { variant: 'error', preventDuplicate: true });
            }
            console.error('Error fetching business teams list:', error);
        } finally {
            setLoading(false);
        }
    };

    const debouncedFetchConfigurationsList = useCallback(
        debounce(
            (
                searchTerm: string,
                categoryStatus: string,
                currentPage: number,
                currentRowsPerPage: number,
                currentSortBy: string,
                currentSortDir: string,
            ) => {
                fetchConfigurationsList(
                    searchTerm,
                    categoryStatus,
                    currentPage,
                    currentRowsPerPage,
                    currentSortBy,
                    currentSortDir,
                );
            },
            500,
        ),
        [],
    );

    useEffect(() => {
        debouncedFetchConfigurationsList(searchTerm, categoryStatus, page + 1, rowsPerPage, sortBy, sortDir);
    }, [page, rowsPerPage, sortBy, sortDir]);

    const handleOpenModal = (isEditing: boolean, teamName: string = '', teamId: number | null = null): void => {
        setIsEditing(isEditing);
        setTeamName(teamName);
        setEditTeamId(teamId);
        setOpen(true);
    };

    const handleCloseModal = (): void => {
        setOpen(false);
        setTeamName('');
        setEditTeamId(null);
    };

    const handleOpenArchiveModal = (teamName: string = '', teamId: number | null = null): void => {
        setTeamName(teamName);
        setEditTeamId(teamId);
        setArchiveOpen(true);
    };

    const handleCloseArchiveModal = (): void => {
        setArchiveOpen(false);
        setTeamName('');
        setEditTeamId(null);
    };

    const handleOpenRestoreModal = (teamName: string = '', teamId: number | null = null): void => {
        setTeamName(teamName);
        setEditTeamId(teamId);
        setRestoreOpen(true);
    };

    const handleCloseRestoreModal = (): void => {
        setRestoreOpen(false);
        setTeamName('');
        setEditTeamId(null);
    };

    const handleSave = async (): void => {
        try {
            if (isEditing && editTeamId) {
                await updateConfigurationCategory(editTeamId, { name: teamName });
                enqueueSnackbar(`Configuration category updated successfully`, {
                    variant: 'success',
                    preventDuplicate: true,
                });
            } else {
                await createConfigurationCategory({ name: teamName });
                enqueueSnackbar(`Configuration category created successfully`, {
                    variant: 'success',
                    preventDuplicate: true,
                });
            }
            handleCloseModal();
            debouncedFetchConfigurationsList(searchTerm, categoryStatus, page + 1, rowsPerPage, sortBy, sortDir);
        } catch (error: unknown) {
            console.error('Error saving business team:', error);

            if (error.statusCode === 400 && error.type === 'validation_error') {
                const validationMessages = error.details.violations.violations
                    .map((violation: unknown) => violation.title)
                    .join('\n');
                enqueueSnackbar(`${error.title}: ${validationMessages}`, { variant: 'error', preventDuplicate: true });
            } else {
                enqueueSnackbar(`${error?.title || 'Error occurred'}`, { variant: 'error', preventDuplicate: true });
            }
        }
    };

    const fetchConfigurationsCategoriesList = async (): Promise<void> => {
        try {
            const list = await getConfigurationsCategoriesList({ perPage: 1000 });
            dispatch(setConfigurationsCategoriesFullList(list));
        } catch (error) {
            console.error('Error fetching business teams list:', error);
        }
    };

    const handleArchive = async (teamId: number): void => {
        try {
            handleCloseArchiveModal();
            await archiveConfigurationCategory(teamId);
            enqueueSnackbar(`Configuration category archived successfully`, {
                variant: 'success',
                preventDuplicate: true,
            });
            debouncedFetchConfigurationsList(searchTerm, categoryStatus, page + 1, rowsPerPage, sortBy, sortDir);
            fetchConfigurationsCategoriesList();
        } catch (error) {
            enqueueSnackbar(`${error?.title || 'Error occurred'}`, { variant: 'error', preventDuplicate: true });
            handleCloseArchiveModal();
            console.error('Error saving business team:', error);
        }
    };

    const handleRestore = async (teamId: number, withConfigurations: boolean): void => {
        try {
            handleCloseRestoreModal();
            await restoreConfigurationCategory(teamId, withConfigurations);
            enqueueSnackbar(`Configuration category restored successfully`, {
                variant: 'success',
                preventDuplicate: true,
            });
            debouncedFetchConfigurationsList(searchTerm, categoryStatus, page + 1, rowsPerPage, sortBy, sortDir);
        } catch (error) {
            enqueueSnackbar(`${error?.title || 'Error occurred'}`, { variant: 'error', preventDuplicate: true });
            handleCloseRestoreModal();
            console.error('Error saving business team:', error);
        }
    };

    return (
        <div style={{ padding: '25px' }}>
            <Paper style={{ padding: '25px' }}>
                <CustomButton label="+ Create a new category" onClick={() => handleOpenModal(false)} />
                <div style={{ display: 'flex', alignItems: 'flex-end', margin: '15px 0', gap: '15px' }}>
                    <CustomTextField label="Search" value={searchTerm} onChange={handleSearchChange} fullWidth />
                    <CustomSelect
                        label="Status"
                        value={categoryStatus || 'none'}
                        onChange={(e) => handleCategoryStatusChange(e)}
                        fullWidth
                    >
                        <MenuItem value="none" disabled>
                            None
                        </MenuItem>
                        {Object.values(CONFIGURATIONS_CATEGORY_STATUS).map((item: string) => (
                            <MenuItem key={item} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </CustomSelect>
                    <ClearIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setSearchTerm('');
                            setCategoryStatus('');
                            debouncedFetchConfigurationsList('', '', 1, rowsPerPage, sortBy, sortDir);
                        }}
                    />
                    <CustomButton
                        label="Submit"
                        onClick={() =>
                            debouncedFetchConfigurationsList(
                                searchTerm,
                                categoryStatus,
                                1,
                                rowsPerPage,
                                sortBy,
                                sortDir,
                            )
                        }
                    />
                </div>

                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '25px' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {['id', 'name', 'status'].map((column) => (
                                            <TableCell style={{ border: '1px solid #030F2B' }} key={column}>
                                                {['id', 'name'].includes(column) ? (
                                                    <TableSortLabel
                                                        active={sortBy === column}
                                                        direction={sortBy === column ? sortDir : 'asc'}
                                                        onClick={() => handleSortChange(column)}
                                                    >
                                                        {column.charAt(0).toUpperCase() + column.slice(1)}
                                                    </TableSortLabel>
                                                ) : (
                                                    column.charAt(0).toUpperCase() + column.slice(1)
                                                )}
                                            </TableCell>
                                        ))}
                                        <TableCell style={{ border: '1px solid #030F2B' }}>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {configurationsList.items.map((item) => (
                                        <TableRow key={item.id}>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>{item.id}</TableCell>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>{item.name}</TableCell>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>{item.status}</TableCell>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>
                                                <CustomButton
                                                    label="Edit"
                                                    onClick={() => handleOpenModal(true, item.name, item.id)}
                                                />
                                                {item.status === CONFIGURATIONS_CATEGORY_STATUS.ACTIVE ? (
                                                    <CustomButton
                                                        label="Archive"
                                                        onClick={() => {
                                                            handleOpenArchiveModal(item.name, item.id);
                                                        }}
                                                    />
                                                ) : (
                                                    <CustomButton
                                                        label="Restore"
                                                        onClick={() => {
                                                            handleOpenRestoreModal(item.name, item.id);
                                                        }}
                                                    />
                                                )}
                                                {/* <Link to={`/user/create`} state={team}>Clone</Link> */}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={configurationsList.totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                )}
                <Dialog open={open} onClose={handleCloseModal}>
                    <DialogTitle>{isEditing ? `Edit ${teamName}` : 'Create a new category'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {isEditing ? 'Edit the details of the category.' : 'Enter the name of the category.'}
                        </DialogContentText>
                        <TextField
                            margin="dense"
                            label="Category"
                            type="text"
                            fullWidth
                            value={teamName}
                            onChange={(e) => setTeamName(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseModal} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleSave} color="primary">
                            {isEditing ? 'Save' : 'Create'}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={openArchive} onClose={handleCloseArchiveModal}>
                    <DialogTitle>Do you really want to archive &quot;{teamName}&quot; category?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            This will result in the archiving of the linked configuration.
                        </DialogContentText>
                        {/*<TextField*/}
                        {/*  margin="dense"*/}
                        {/*  label="Team Name"*/}
                        {/*  type="text"*/}
                        {/*  fullWidth*/}
                        {/*  value={teamName}*/}
                        {/*  onChange={(e) => setTeamName(e.target.value)}*/}
                        {/*/>*/}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseArchiveModal} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => handleArchive(editTeamId)} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={openRestore} onClose={handleCloseRestoreModal}>
                    <DialogTitle>Do you really want to restore &quot;{teamName}&quot; category?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            This will result in the archiving of the linked configuration.
                        </DialogContentText>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={valueRadio}
                            onChange={handleRadioChange}
                        >
                            <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label="I want to restore the category and linked configuration"
                            />
                            <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label="I want to restore the category itself"
                            />
                        </RadioGroup>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseRestoreModal} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => handleRestore(editTeamId, valueRadio)} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        </div>
    );
};

export default ConfigurationsCategories;
