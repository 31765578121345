import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@/store';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    TableSortLabel,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    MenuItem,
    CircularProgress,
} from '@mui/material';
import debounce from 'lodash/debounce';
import {
    getConfigurationsCategoriesFullListFromState,
    getConfigurationsListFromState,
    setConfigurationsList,
} from '@/store/configurationsSlice';
import { useConfigurationsApi } from '@/api-client/configurations-api';
import { Link } from 'react-router-dom';
import { CONFIGURATIONS_CATEGORY_STATUS, CONFIGURATIONS_CATEGORY_STATUS_ALL, ConfigurationsCategories } from '@/types';
import CreateNewConfiguration from '@/modules/configurations/configuration/create-new-configuration';
import CustomButton from '@/components/custom-button';
import CustomSelect from '@/components/custom-select';
import CustomTextField from '@/components/custom-text-field';
import ClearIcon from '@mui/icons-material/Clear';
import { useSnackbar } from 'notistack';

const ConfigurationsList: React.FC = () => {
    const { getConfigurationsList, postConfigurationArchive, postConfigurationRestore } = useConfigurationsApi();
    const dispatch: AppDispatch = useDispatch();
    const configurationsList = useSelector(getConfigurationsListFromState);
    const configurationsCategoriesList = useSelector(getConfigurationsCategoriesFullListFromState);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [category, setCategory] = useState<string>('');
    const [categoryStatus, setCategoryStatus] = useState<string>('');
    const [sortBy, setSortBy] = useState<string>('id');
    const [sortDir, setSortDir] = useState<'asc' | 'desc'>('desc');
    const [openArchive, setArchiveOpen] = useState(false);
    const [openRestore, setRestoreOpen] = useState(false);
    const [teamName, setTeamName] = useState('');
    const [editTeamId, setEditTeamId] = useState<number | null>(null);
    const [loading, setLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();

    const handleChangePage = (event: unknown, newPage: number): void => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchTerm(event.target.value);
    };

    const handleCategoryStatusChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
        setCategoryStatus(event.target.value as string);
    };

    const handleSortChange = (property: string): void => {
        const isAsc = sortBy === property && sortDir === 'asc';
        setSortDir(isAsc ? 'desc' : 'asc');
        setSortBy(property);
    };

    const handleCategoryChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
        setCategory(event.target.value as string);
    };

    const fetchConfigurationsList = async (
        keyword: string,
        category: string,
        categoryStatus: string,
        currentPage: number,
        currentRowsPerPage: number,
        currentSortBy: string,
        currentSortDir: string,
    ): Promise<void> => {
        setLoading(true);
        try {
            const list = await getConfigurationsList({
                keyword,
                categoryId: category,
                status: categoryStatus,
                page: currentPage,
                perPage: currentRowsPerPage,
                sortBy: currentSortBy,
                sortDir: currentSortDir,
            });
            dispatch(setConfigurationsList(list));
        } catch (error) {
            if (error?.details?.violations) {
                error.details.violations.violations.forEach((violation: unknown) => {
                    enqueueSnackbar(`${violation.propertyPath}: ${violation.title}`, {
                        variant: 'error',
                        preventDuplicate: true,
                    });
                });
            } else {
                enqueueSnackbar(`${error?.title || 'Error occurred'}`, { variant: 'error', preventDuplicate: true });
            }
            console.error('Error fetching business teams list:', error);
        } finally {
            setLoading(false);
        }
    };

    const debouncedFetchConfigurationsList = useCallback(
        debounce(
            (
                keyword: string,
                category: string,
                categoryStatus: string,
                currentPage: number,
                currentRowsPerPage: number,
                currentSortBy: string,
                currentSortDir: string,
            ) => {
                fetchConfigurationsList(
                    keyword,
                    category,
                    categoryStatus,
                    currentPage,
                    currentRowsPerPage,
                    currentSortBy,
                    currentSortDir,
                );
            },
            500,
        ),
        [],
    );

    useEffect(() => {
        debouncedFetchConfigurationsList(searchTerm, category, categoryStatus, page + 1, rowsPerPage, sortBy, sortDir);
    }, [page, rowsPerPage, sortBy, sortDir]);

    const handleOpenArchiveModal = (teamName: string = '', teamId: number | null = null): void => {
        setTeamName(teamName);
        setEditTeamId(teamId);
        setArchiveOpen(true);
    };

    const handleCloseArchiveModal = (): void => {
        setArchiveOpen(false);
        setTeamName('');
        setEditTeamId(null);
    };

    const handleOpenRestoreModal = (teamName: string = '', teamId: number | null = null): void => {
        setTeamName(teamName);
        setEditTeamId(teamId);
        setRestoreOpen(true);
    };

    const handleCloseRestoreModal = (): void => {
        setRestoreOpen(false);
        setTeamName('');
        setEditTeamId(null);
    };

    const handleRestore = async (teamId: number): void => {
        try {
            await postConfigurationRestore(teamId);
            handleCloseRestoreModal();
            debouncedFetchConfigurationsList(
                searchTerm,
                category,
                categoryStatus,
                page + 1,
                rowsPerPage,
                sortBy,
                sortDir,
            );
            enqueueSnackbar(`Configuration restored successfully`, {
                variant: 'success',
                preventDuplicate: true,
            });
        } catch (error) {
            enqueueSnackbar(`${error?.title || 'Error occurred'}`, { variant: 'error', preventDuplicate: true });
            console.error('Error restoring configuration:', error);
        }
    };

    const handleArchive = async (teamId: number): void => {
        try {
            handleCloseArchiveModal();
            await postConfigurationArchive(teamId);
            debouncedFetchConfigurationsList(
                searchTerm,
                category,
                categoryStatus,
                page + 1,
                rowsPerPage,
                sortBy,
                sortDir,
            );
            enqueueSnackbar(`Configuration archived successfully`, {
                variant: 'success',
                preventDuplicate: true,
            });
        } catch (error) {
            handleCloseArchiveModal();
            enqueueSnackbar(`${error?.title || 'Error occurred'}`, { variant: 'error', preventDuplicate: true });
            console.error('Error archiving configuration:', error);
        }
    };

    return (
        <div style={{ padding: '25px' }}>
            <Paper style={{ padding: '25px' }}>
                <CreateNewConfiguration />
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        margin: '15px 0',
                        gap: '15px',
                    }}
                >
                    <CustomTextField label="Search" value={searchTerm} onChange={handleSearchChange} fullWidth />
                    <CustomSelect
                        label="Status"
                        value={categoryStatus || 'none'}
                        onChange={(e) => handleCategoryStatusChange(e)}
                        fullWidth
                    >
                        <MenuItem value="none" disabled>
                            None
                        </MenuItem>
                        {Object.values(CONFIGURATIONS_CATEGORY_STATUS_ALL).map((item: string) => (
                            <MenuItem key={item} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </CustomSelect>
                    <CustomSelect
                        label="Category"
                        value={category || 'none'}
                        onChange={(e) => handleCategoryChange(e)}
                        fullWidth
                    >
                        {configurationsCategoriesList.items
                            .filter(
                                (item): item is ConfigurationsCategories =>
                                    item.status === CONFIGURATIONS_CATEGORY_STATUS.ACTIVE && 'name' in item,
                            )
                            .map((item: ConfigurationsCategories) => {
                                return (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                );
                            })}
                    </CustomSelect>
                    <ClearIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setSearchTerm('');
                            setCategory('');
                            setCategoryStatus('');
                            debouncedFetchConfigurationsList('', '', '', 1, rowsPerPage, sortBy, sortDir);
                        }}
                    />
                    <CustomButton
                        label="Submit"
                        onClick={() =>
                            debouncedFetchConfigurationsList(
                                searchTerm,
                                category,
                                categoryStatus,
                                1,
                                rowsPerPage,
                                sortBy,
                                sortDir,
                            )
                        }
                    ></CustomButton>
                </div>
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '25px' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {['id', 'displayName', 'status', 'category'].map((column) => (
                                            <TableCell style={{ border: '1px solid #030F2B' }} key={column}>
                                                {['id', 'displayName'].includes(column) ? (
                                                    <TableSortLabel
                                                        active={sortBy === column}
                                                        direction={sortBy === column ? sortDir : 'asc'}
                                                        onClick={() => handleSortChange(column)}
                                                    >
                                                        {column.charAt(0).toUpperCase() + column.slice(1)}
                                                    </TableSortLabel>
                                                ) : (
                                                    column.charAt(0).toUpperCase() + column.slice(1)
                                                )}
                                            </TableCell>
                                        ))}
                                        <TableCell style={{ border: '1px solid #030F2B' }}>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {configurationsList.items.map((item) => (
                                        <TableRow key={item.id}>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>{item.id}</TableCell>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>
                                                {item.displayName}
                                            </TableCell>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>{item.status}</TableCell>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>
                                                {item.categoryResource.name}
                                            </TableCell>
                                            <TableCell style={{ border: '1px solid #B1BACE' }}>
                                                <Link to={`/configuration/${item.id}`}>
                                                    <CustomButton label="Edit" />
                                                </Link>
                                                {item.status === 'Active' ? (
                                                    <CustomButton
                                                        label="Archive"
                                                        onClick={() => {
                                                            handleOpenArchiveModal(item.displayName, item.id);
                                                        }}
                                                    />
                                                ) : (
                                                    <CustomButton
                                                        label="Restore"
                                                        onClick={() => {
                                                            handleOpenRestoreModal(item.displayName, item.id);
                                                        }}
                                                    />
                                                )}
                                                <Link to={`/configuration/${item.id}/my-uploads`}>
                                                    <CustomButton label="My Uploads" />
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={configurationsList.totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                )}
                <Dialog open={openArchive} onClose={handleCloseArchiveModal}>
                    <DialogTitle>Do you really want to archive &quot;{teamName}&quot; configuration?</DialogTitle>
                    <DialogContent></DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseArchiveModal} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => handleArchive(editTeamId)} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={openRestore} onClose={handleCloseRestoreModal}>
                    <DialogTitle>Do you really want to restore &quot;{teamName}&quot; configuration?</DialogTitle>
                    <DialogContent></DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseRestoreModal} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => handleRestore(editTeamId)} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        </div>
    );
};

export default ConfigurationsList;
