import React, { useState, useEffect, useCallback } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    CircularProgress,
    TableSortLabel,
    Collapse,
    IconButton,
    MenuItem,
    ListSubheader,
} from '@mui/material';
import debounce from 'lodash/debounce';
import { useActivityLogsApi } from '@/api-client/activity-logs-api';
import CustomButton from '@/components/custom-button';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CustomTextField from '@/components/custom-text-field';
import CustomSelect from '@/components/custom-select';

const ActivityLogs: React.FC = () => {
    const { getActivityLogsList, getActivityLogsMappings, exportActivityLogs } = useActivityLogsApi();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortBy, setSortBy] = useState<string>('createdAt');
    const [sortDir, setSortDir] = useState<'asc' | 'desc'>('desc');
    const [logs, setLogs] = useState<unknown[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [expandedRow, setExpandedRow] = useState<number | null>(null);

    const [entityId, setEntityId] = useState<number | null>(null);
    const [userId, setUserId] = useState<number | null>(null);
    const [dateStart, setDateStart] = useState<string | null>(null);
    const [dateEnd, setDateEnd] = useState<string | null>(null);
    const [entityName, setEntityName] = useState<string | null>(null);
    const [activityName, setActivityName] = useState<string | null>(null);

    const [activityMappings, setActivityMappings] = useState<{ [key: string]: string[] }>({});

    const handleChangePage = (event: unknown, newPage: number): void => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSortChange = (property: string): void => {
        const isAsc = sortBy === property && sortDir === 'asc';
        setSortDir(isAsc ? 'desc' : 'asc');
        setSortBy(property);
    };

    const fetchActivityMappings = async (): Promise<void> => {
        try {
            const response = await getActivityLogsMappings();
            setActivityMappings(response);
        } catch (error) {
            console.error('Error fetching activity mappings:', error);
        }
    };

    const handleSubmit = (): void => {
        setPage(0);
        fetchActivityLogs({
            page: 1,
            perPage: rowsPerPage,
            sortBy,
            sortDir,
            entityId,
            userId,
            dateStart,
            dateEnd,
            entityName,
            activityName,
        });
    };

    const handleClear = (): void => {
        setEntityId(null);
        setUserId(null);
        setDateStart(null);
        setDateEnd(null);
        setEntityName(null);
        setActivityName(null);
        setPage(0);
        setRowsPerPage(10);
        setSortBy('createdAt');
        setSortDir('desc');
        debouncedFetchActivityLogs(null, null, null, null, null, null, 0, 10, 'createdAt', 'desc');
    };

    const debouncedFetchActivityLogs = useCallback(
        debounce(
            (
                entityId: number | null,
                userId: number | null,
                dateStart: string | null,
                dateEnd: string | null,
                entityName: string | null,
                activityName: string | null,
                currentPage: number = 0,
                currentRowsPerPage: number = 10,
                currentSortBy: string = 'createdAt',
                currentSortDir: 'asc' | 'desc' = 'desc',
            ) => {
                fetchActivityLogs({
                    page: currentPage + 1,
                    perPage: currentRowsPerPage,
                    sortBy: currentSortBy,
                    sortDir: currentSortDir,
                    entityId,
                    userId,
                    dateStart,
                    dateEnd,
                    entityName,
                    activityName,
                });
            },
            500,
        ),
        [],
    );

    const fetchActivityLogs = async ({
        page,
        perPage,
        sortBy,
        sortDir,
        entityId,
        userId,
        dateStart,
        dateEnd,
        entityName,
        activityName,
    }: {
        page: number;
        perPage: number;
        sortBy: string;
        sortDir: 'asc' | 'desc';
        entityId: number | null;
        userId: number | null;
        dateStart: string | null;
        dateEnd: string | null;
        entityName: string | null;
        activityName: string | null;
    }): Promise<void> => {
        setLoading(true);
        try {
            const response = await getActivityLogsList({
                page,
                perPage,
                sortBy,
                sortDir,
                entityId,
                userId,
                dateStart,
                dateEnd,
                entityName,
                activityName,
            });
            setLogs(response.items);
            setTotalCount(response.totalCount);
        } catch (error) {
            console.error('Error fetching activity logs:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchActivityLogs({
            page: page + 1,
            perPage: rowsPerPage,
            sortBy,
            sortDir,
            entityId,
            userId,
            dateStart,
            dateEnd,
            entityName,
            activityName,
        });
    }, [page, rowsPerPage, sortBy, sortDir]);

    useEffect(() => {
        fetchActivityMappings();
        console.log(activityMappings);
    }, []);

    const handleExpandClick = (rowId: number): void => {
        setExpandedRow(expandedRow === rowId ? null : rowId);
    };

    const handleExport = async (): Promise<void> => {
        try {
            await exportActivityLogs({
                page: page + 1,
                perPage: rowsPerPage,
                sortBy,
                sortDir,
                entityId,
                userId,
                dateStart,
                dateEnd,
                entityName,
                activityName,
            });
        } catch (error) {
            console.error('Error exporting activity logs:', error);
        }
    };

    const capitalizeFirstLetter = (str: string): string => {
        return str
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    return (
        <div style={{ padding: '25px' }}>
            <Paper style={{ padding: '25px' }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '15px 0',
                        gap: '15px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            gap: '15px',
                        }}
                    >
                        <CustomTextField
                            label="Entity ID"
                            value={entityId?.toString() || ''}
                            onChange={(e) => {
                                let value = e.target.value;
                                value = value.replace(/[^0-9]/g, '');
                                const numberValue = value ? parseInt(value, 10) : null;
                                setEntityId(numberValue);
                            }}
                            style={{ minWidth: 150 }}
                        />
                        <CustomTextField
                            label="User ID"
                            value={userId?.toString() || ''}
                            onChange={(e) => {
                                let value = e.target.value;
                                value = value.replace(/[^0-9]/g, '');
                                const numberValue = value ? parseInt(value, 10) : null;
                                setUserId(numberValue);
                            }}
                            style={{ minWidth: 150 }}
                        />
                        <CustomTextField
                            label="Date Start"
                            type="date"
                            value={dateStart || ''}
                            onChange={(e) => setDateStart(e.target.value || null)}
                            style={{ minWidth: 150 }}
                        />
                        <CustomTextField
                            label="Date End"
                            type="date"
                            value={dateEnd || ''}
                            onChange={(e) => setDateEnd(e.target.value || null)}
                            style={{ minWidth: 150 }}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            gap: '15px',
                        }}
                    >
                        <CustomSelect
                            label="Entity name"
                            value={entityName || ''}
                            onChange={(e) => setEntityName((e.target.value as string) || null)}
                            displayEmpty
                            fullWidth
                            style={{ minWidth: 200 }}
                        >
                            <MenuItem value="">None</MenuItem>
                            <MenuItem value="Configuration">Configuration</MenuItem>
                            <MenuItem value="User">User</MenuItem>
                            <MenuItem value="Category">Category</MenuItem>
                            <MenuItem value="BusinessTeam">BusinessTeam</MenuItem>
                            <MenuItem value="File">File</MenuItem>
                            <MenuItem value="Upload history">Upload history</MenuItem>
                            <MenuItem value="Draft configuration">Draft configuration</MenuItem>
                        </CustomSelect>
                        <CustomSelect
                            label="Activity name"
                            value={activityName || ''}
                            onChange={(e) => setActivityName((e.target.value as string) || null)}
                            displayEmpty
                            fullWidth
                            style={{ minWidth: 200 }}
                        >
                            <MenuItem value="">None</MenuItem>
                            {entityName
                                ? activityMappings[entityName]?.map((name) => (
                                      <MenuItem key={`${entityName}-${name}`} value={name}>
                                          {capitalizeFirstLetter(name.replace(/_/g, ' '))}
                                      </MenuItem>
                                  ))
                                : Object.entries(activityMappings).flatMap(([entity, names], index) => [
                                      <ListSubheader key={`header-${index}-${entity}`} disableSticky>
                                          {capitalizeFirstLetter(entity.replace(/_/g, ' '))}
                                      </ListSubheader>,
                                      ...names.map((name, nameIndex) => (
                                          <MenuItem key={`${entity}-${nameIndex}-${name}`} value={name}>
                                              {capitalizeFirstLetter(name.replace(/_/g, ' '))}
                                          </MenuItem>
                                      )),
                                  ])}
                        </CustomSelect>
                        <ClearIcon style={{ cursor: 'pointer' }} onClick={handleClear} />
                        <CustomButton label="Submit" onClick={handleSubmit} />
                    </div>
                </div>
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '25px' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {['createdAt', 'user', 'message', 'entityId', 'entityName'].map((column) => (
                                            <TableCell style={{ border: '1px solid #030F2B' }} key={column}>
                                                {['createdAt', 'user'].includes(column) ? (
                                                    <TableSortLabel
                                                        active={sortBy === column}
                                                        direction={sortBy === column ? sortDir : 'asc'}
                                                        onClick={() => handleSortChange(column)}
                                                    >
                                                        {column.charAt(0).toUpperCase() + column.slice(1)}
                                                    </TableSortLabel>
                                                ) : (
                                                    column.charAt(0).toUpperCase() + column.slice(1)
                                                )}
                                            </TableCell>
                                        ))}
                                        <TableCell style={{ border: '1px solid #030F2B' }}>Details</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {logs.map((log: unknown) => (
                                        <React.Fragment key={log.id}>
                                            <TableRow>
                                                <TableCell style={{ border: '1px solid #B1BACE' }}>
                                                    {new Date(log.createdAt).toLocaleString()}
                                                </TableCell>
                                                <TableCell style={{ border: '1px solid #B1BACE' }}>
                                                    {log.userResource
                                                        ? `${log.userResource.firstName} ${log.userResource.lastName}`
                                                        : 'No user data'}
                                                </TableCell>
                                                <TableCell style={{ border: '1px solid #B1BACE' }}>
                                                    {log.message}
                                                </TableCell>
                                                <TableCell style={{ border: '1px solid #B1BACE' }}>
                                                    {log.entityId}
                                                </TableCell>
                                                <TableCell style={{ border: '1px solid #B1BACE' }}>
                                                    {log.entityName}
                                                </TableCell>
                                                <TableCell style={{ border: '1px solid #B1BACE' }}>
                                                    <IconButton onClick={() => handleExpandClick(log.id)}>
                                                        {expandedRow === log.id ? (
                                                            <ExpandLessIcon />
                                                        ) : (
                                                            <ExpandMoreIcon />
                                                        )}
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ padding: 0 }} colSpan={6}>
                                                    <Collapse in={expandedRow === log.id} timeout="auto" unmountOnExit>
                                                        <div style={{ padding: '10px', border: '1px solid #B1BACE' }}>
                                                            <pre>{JSON.stringify(log.context, null, 2)}</pre>
                                                        </div>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                        <div style={{ marginTop: '20px', textAlign: 'right' }}>
                            <CustomButton label="Export" onClick={handleExport} />
                        </div>
                    </>
                )}
            </Paper>
        </div>
    );
};

export default ActivityLogs;
