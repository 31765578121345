import React, { useEffect, useRef, useState } from 'react';
import {
    Paper,
    CircularProgress,
    Typography,
    Button,
    InputAdornment,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import { useParams, useLocation, Link } from 'react-router-dom';
import { ConfigurationResponse } from '@/types';
import { AppDispatch } from '@/store';
import { useDispatch, useSelector } from 'react-redux';
import {
    getConfigurationsCurrentConfiguration,
    getDefaultCurrentConfiguration,
    setConfigurationFileTemplate,
    setConfigurationSchemaResource,
    setCurrentConfiguration,
    setCurrentConfigurationBQModeMap,
    setCurrentConfigurationDetails,
    setCurrentConfigurationErrorsNumber,
    setCurrentConfigurationId,
    setCurrentConfigurationScheduledTimeToPush,
    setCurrentConfigurationSendingDataToBQ,
    setCurrentConfigurationStatus,
    setCurrentConfigurationUploadHistoryStatus,
    setCurrentConfigurationValidationErrors,
    setCurrentConfigurationVersionId,
} from '@/store/configurationsSlice';
import debounce from 'lodash/debounce';
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useSnackbar } from 'notistack';
import CustomTextField from '@/components/custom-text-field';
import CustomButton from '@/components/custom-button';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAdminUploadHistoryApi } from '@/api-client/admin-upload-history-api';
import { useAdminConfigurationApi } from '@/api-client/admin-configuration-api';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Tooltip from '@mui/material/Tooltip';

const InfoIcon: () => JSX.Element = (): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.5003 9.99984C17.5003 14.142 14.1425 17.4998 10.0003 17.4998C5.85819 17.4998 2.50033 14.142 2.50033 9.99984C2.50033 5.8577 5.85819 2.49984 10.0003 2.49984C14.1425 2.49984 17.5003 5.8577 17.5003 9.99984ZM18.3337 9.99984C18.3337 14.6022 14.6027 18.3332 10.0003 18.3332C5.39795 18.3332 1.66699 14.6022 1.66699 9.99984C1.66699 5.39746 5.39795 1.6665 10.0003 1.6665C14.6027 1.6665 18.3337 5.39746 18.3337 9.99984ZM9.58366 13.3332V14.1665H10.417V13.3332H9.58366ZM9.58366 5.83317V11.6665H10.417V5.83317H9.58366Z"
                fill="#D62623"
            />
        </svg>
    );
};

const ViewDataConfigurationAdmin: React.FC = () => {
    const [configurationId, setConfigurationId] = useState(null);
    const [currentHistoryId, setCurrentHistoryId] = useState(null);
    const [dataList, setDataList] = useState(null);
    const [cursor, setCursor] = useState(null);
    const [currentFilter, setCurrentFilter] = useState('all');
    const [currentItems, setCurrentItems] = useState([]);
    const { id, historyId } = useParams<{ id: string }>();
    const location = useLocation();
    const dispatch: AppDispatch = useDispatch();
    const configuration = useSelector(getConfigurationsCurrentConfiguration);
    const [loading, setLoading] = useState(false);
    const {
        getUploadHistory,
        getUploadHistoryDataList,
        updateUploadHistoryData,
        getUploadHistoryManuallySend,
        patchConfigurationAdminUserUploadHistoryAutomaticSendingResume,
        patchConfigurationAdminUserUploadHistoryAutomaticSendingPause,
    } = useAdminUploadHistoryApi();
    const { getAdminConfigurationUploadsShow } = useAdminConfigurationApi();
    const { enqueueSnackbar } = useSnackbar();
    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState<string | null>(null);
    const changesRef = useRef<{ cellId: number; value: string; errorsNumber: number; cellErrorsQty: number }[]>([]);

    const debouncedUpdate = useRef(
        debounce(async () => {
            if (changesRef.current.length > 0) {
                const changesToProcess = [...changesRef.current];
                changesRef.current = [];

                for (const { cellId, value, errorsNumber, cellErrorsQty } of changesToProcess) {
                    try {
                        const processedValue = value === '' ? null : value;

                        const response = await updateUploadHistoryData(
                            currentHistoryId || historyId,
                            cellId,
                            processedValue,
                        );

                        setDataList((prevDataList) => ({
                            ...prevDataList,
                            items: prevDataList.items.map((item) => ({
                                ...item,
                                cells: item.cells.map((cell) =>
                                    cell.id === cellId
                                        ? {
                                              ...cell,
                                              value: response.value,
                                              errors: response.errors,
                                              status: response.status,
                                              loading: false,
                                              success: response.status !== 'error',
                                          }
                                        : cell,
                                ),
                            })),
                        }));

                        dispatch(setCurrentConfigurationStatus(response?.uploadHistoryStatus));

                        if (response?.status === 'valid' && errorsNumber > 0) {
                            dispatch(setCurrentConfigurationErrorsNumber(errorsNumber - cellErrorsQty));
                        } else if (response.status === 'error') {
                            if (response?.errors?.length) {
                                response.errors.forEach((error) => {
                                    enqueueSnackbar(error, { variant: 'error', preventDuplicate: true });
                                });
                            } else {
                                enqueueSnackbar('Error occurred', { variant: 'error', preventDuplicate: true });
                            }
                        }
                    } catch (error) {
                        console.error('Error processing change:', error);
                    }
                }
            }
        }, 1000),
    ).current;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, cellId: number, cellErrorsQty: number): void => {
        const { value } = event.target;

        setDataList((prevDataList) => ({
            ...prevDataList,
            items: prevDataList.items.map((item) => ({
                ...item,
                cells: item.cells.map((cell) =>
                    cell.id === cellId ? { ...cell, value, loading: true, success: false, error: false } : cell,
                ),
            })),
        }));

        const existingChangeIndex = changesRef.current.findIndex((change) => change.cellId === cellId);

        if (existingChangeIndex > -1) {
            changesRef.current[existingChangeIndex] = {
                cellId,
                value,
                errorsNumber: configuration.errorsNumber,
                cellErrorsQty,
            };
        } else {
            changesRef.current.push({
                cellId,
                value,
                errorsNumber: configuration.errorsNumber,
                cellErrorsQty,
            });
        }

        debouncedUpdate();
    };

    useEffect(() => {
        return () => {
            debouncedUpdate.cancel();
        };
    }, []);

    const handleOpenModal = (content: React.ReactNode): void => {
        setModalContent(content);
        setOpenModal(true);
    };

    const handleCloseModal = (): void => {
        setOpenModal(false);
        setTimeout(() => {
            setModalContent(null);
        }, 100);
    };

    useEffect(() => {
        if (configuration?.id) {
            setConfigurationId(configuration?.id);
        }
    }, [configuration.id]);

    const transformToConfiguration = (configuration: unknown): ConfigurationResponse => {
        const configurationDetails = {
            technicalName: {
                value: configuration?.detailsResource?.technicalName ?? '',
                loading: false,
                success: false,
                error: false,
            },
            displayName: {
                value: configuration?.displayName ?? '',
                loading: false,
                success: false,
                error: false,
            },
            category: {
                value: configuration?.categoryResource?.name ?? '',
                loading: false,
                success: false,
                error: false,
            },
            description: {
                value: configuration?.detailsResource?.description ?? '',
                loading: false,
                success: false,
                error: false,
            },
            fileFormat: {
                value: configuration?.detailsResource?.fileType ?? '',
                loading: false,
                success: false,
                error: false,
            },
            version: {
                value: configuration?.versionResource?.version ?? '',
                loading: false,
                success: false,
                error: false,
            },
            versionComment: {
                value: configuration?.versionResource?.description ?? '',
                loading: false,
                success: false,
                error: false,
            },
            ownerEmail: configuration?.ownerEmail,
        };
        const configurationFileTemplate = configuration?.templateResource
            ? {
                  createdAt: configuration?.templateResource?.createdAt,
                  uuid: configuration?.templateResource?.uuid,
                  originalName: configuration?.templateResource?.originalName,
                  extension: configuration?.templateResource?.extension,
              }
            : null;
        const configurationSendingOptions = configuration?.sendingOptionsResource ?? null;
        dispatch(setCurrentConfigurationId(configuration?.id));
        dispatch(setCurrentConfigurationVersionId(configuration?.versionResources?.id ?? ''));
        dispatch(setCurrentConfigurationDetails(configurationDetails));
        dispatch(setConfigurationFileTemplate(configurationFileTemplate));
        dispatch(setCurrentConfigurationSendingDataToBQ(configurationSendingOptions));
        dispatch(setCurrentConfigurationBQModeMap(configuration?.bigQueryModeMap));
    };

    useEffect(() => {
        return () => {
            dispatch(setCurrentConfiguration(getDefaultCurrentConfiguration()));
        };
    }, []);

    const fetchConfiguration = async (): Promise<void> => {
        try {
            setLoading(true);
            const configuration = await getAdminConfigurationUploadsShow(configurationId || id);
            transformToConfiguration(configuration);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    const fetchUploadHistory = async (): Promise<void> => {
        try {
            setLoading(true);
            const configuration = await getUploadHistory(configurationId || id, currentHistoryId || historyId);
            dispatch(setConfigurationSchemaResource(configuration?.schemaResource));
            dispatch(setCurrentConfigurationErrorsNumber(configuration?.errorsNumber));
            dispatch(setCurrentConfigurationStatus(configuration?.status));
            dispatch(setCurrentConfigurationValidationErrors(configuration?.validationErrors));
            dispatch(setCurrentConfigurationScheduledTimeToPush(configuration?.scheduledTimeToPush));
            dispatch(setCurrentConfigurationUploadHistoryStatus(configuration?.status));
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    const fetchUploadHistoryDataList = async (params: { cursor?: number | null; filter?: string }): Promise<void> => {
        try {
            const response = await getUploadHistoryDataList(
                currentHistoryId || historyId,
                params.filter,
                params.cursor,
            );

            setDataList((prevDataList) => ({
                ...response,
                items: params.cursor ? [...(prevDataList?.items || []), ...response.items] : response.items,
            }));

            if (response.items.length > 0) {
                const lastItem = response.items[response.items.length - 1];
                setCursor(lastItem.id);
            } else {
                setCursor(null);
            }
            setCurrentItems(response.items);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchUploadHistoryManuallySend = async (): Promise<void> => {
        try {
            const configuration = await getUploadHistoryManuallySend(currentHistoryId || historyId);
            enqueueSnackbar('The report to BQ will be sent soon', { variant: 'success', preventDuplicate: true });
            dispatch(setCurrentConfigurationUploadHistoryStatus(configuration?.status));
            dispatch(setCurrentConfigurationScheduledTimeToPush(configuration?.scheduledTimeToPush));
        } catch (error) {
            enqueueSnackbar(`${error?.title}`, { variant: 'error', preventDuplicate: true });
            console.error(error);
        }
    };

    const pauseUploadHistoryAdmin = async (uploadHistoryId: string): Promise<void> => {
        try {
            const configuration = await patchConfigurationAdminUserUploadHistoryAutomaticSendingPause(uploadHistoryId);
            dispatch(setCurrentConfigurationUploadHistoryStatus(configuration?.status));
            dispatch(setCurrentConfigurationScheduledTimeToPush(configuration?.scheduledTimeToPush));
            enqueueSnackbar('Automatic sending has been paused', {
                variant: 'success',
                preventDuplicate: true,
            });
        } catch (error) {
            enqueueSnackbar(`${error?.message || 'An error occurred while pausing automatic sending'}`, {
                variant: 'error',
                preventDuplicate: true,
            });
            console.error(error);
        }
    };

    const resumeUploadHistoryAdmin = async (uploadHistoryId: string): Promise<void> => {
        try {
            const configuration = await patchConfigurationAdminUserUploadHistoryAutomaticSendingResume(uploadHistoryId);
            dispatch(setCurrentConfigurationUploadHistoryStatus(configuration?.status));
            dispatch(setCurrentConfigurationScheduledTimeToPush(configuration?.scheduledTimeToPush));
            enqueueSnackbar('Automatic sending has been resumed', {
                variant: 'success',
                preventDuplicate: true,
            });
        } catch (error) {
            enqueueSnackbar(`${error?.message || 'An error occurred while resuming automatic sending'}`, {
                variant: 'error',
                preventDuplicate: true,
            });
            console.error(error);
        }
    };

    useEffect(() => {
        if (id && historyId) {
            fetchUploadHistoryDataList({ filter: currentFilter });
            fetchUploadHistory();
            fetchConfiguration();
            setConfigurationId(id);
            setCurrentHistoryId(historyId);
        }
    }, [id, historyId, location.pathname]);

    if (loading) {
        return (
            <Paper style={{ padding: '25px', textAlign: 'center' }}>
                <CircularProgress />
            </Paper>
        );
    }

    const convertTimestamp = (isoTimestamp: string | null): string => {
        if (isoTimestamp === null) {
            return '';
        }

        const date: Date = new Date(isoTimestamp);

        const day: string = String(date.getDate()).padStart(2, '0');
        const month: string = String(date.getMonth() + 1).padStart(2, '0');
        const year: number = date.getFullYear();

        const hours: string = String(date.getHours()).padStart(2, '0');
        const minutes: string = String(date.getMinutes()).padStart(2, '0');

        return `${day}.${month}.${year} ${hours}:${minutes}`;
    };

    const renderTable = (): null | JSX.Element => {
        const schemaResource = configuration?.schemaResource;
        if (!schemaResource) return null;

        return (
            <>
                <Typography variant="h6" style={{ marginBottom: '5px' }}>
                    <strong>Expected format and value</strong>
                </Typography>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <tbody>
                        <tr>
                            <td
                                style={{
                                    border: '1px solid rgb(177, 186, 206)',
                                    padding: '8px',
                                    textAlign: 'left',
                                    minWidth: '80px',
                                    width: '80px',
                                }}
                            >
                                Position
                            </td>
                            {schemaResource.columns.map((col: unknown, index: number) => (
                                <td
                                    key={index}
                                    style={{
                                        border: '1px solid rgb(177, 186, 206)',
                                        padding: '8px',
                                        textAlign: 'left',
                                        minWidth: '80px',
                                        width: '80px',
                                    }}
                                >
                                    {col.position}
                                </td>
                            ))}
                        </tr>
                        <tr>
                            <td
                                style={{
                                    border: '1px solid rgb(177, 186, 206)',
                                    padding: '8px',
                                    textAlign: 'left',
                                    minWidth: '80px',
                                    width: '80px',
                                }}
                            >
                                Name
                            </td>
                            {schemaResource.columns.map((col: unknown, index: number) => (
                                <td
                                    key={index}
                                    style={{
                                        border: '1px solid rgb(177, 186, 206)',
                                        padding: '8px',
                                        textAlign: 'left',
                                        maxWidth: '150px',
                                        minWidth: '150px',
                                        width: '150px',
                                    }}
                                >
                                    {col.name}
                                </td>
                            ))}
                        </tr>
                        <tr>
                            <td
                                style={{
                                    border: '1px solid rgb(177, 186, 206)',
                                    padding: '8px',
                                    textAlign: 'left',
                                    minWidth: '80px',
                                    width: '80px',
                                }}
                            >
                                Mode
                            </td>
                            {schemaResource.columns.map((col: unknown, index: number) => (
                                <td
                                    key={index}
                                    style={{
                                        border: '1px solid rgb(177, 186, 206)',
                                        padding: '8px',
                                        textAlign: 'left',
                                        minWidth: '80px',
                                        width: '80px',
                                    }}
                                >
                                    {col &&
                                    col.name &&
                                    configuration?.bigQueryModeMap &&
                                    configuration?.bigQueryModeMap[col.name]
                                        ? configuration?.bigQueryModeMap[col.name]
                                        : 'N/A'}
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
                {(configuration?.scheduledTimeToPush || configuration?.uploadHistoryStatus === 'paused') && (
                    <div
                        style={{
                            position: 'sticky',
                            left: 0,
                            top: 0,
                            margin: '15px 0',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'end',
                        }}
                    >
                        <p style={{ margin: 0 }}>
                            {configuration?.uploadHistoryStatus === 'paused'
                                ? 'The upload is paused'
                                : `The upload will be started at: ${convertTimestamp(configuration?.scheduledTimeToPush)}`}
                        </p>
                        {configuration?.sendingDataToBQ?.type === 'automatic' && (
                            <>
                                {configuration?.uploadHistoryStatus === 'paused' ? (
                                    <Tooltip title={`Press "Resume" once everything is ready for upload to BQ`}>
                                        <div
                                            aria-hidden="true"
                                            style={{ display: 'flex', cursor: 'pointer' }}
                                            onClick={() => resumeUploadHistoryAdmin(historyId)}
                                        >
                                            <PlayArrowIcon />
                                        </div>
                                    </Tooltip>
                                ) : (
                                    <Tooltip title={`Press "Pause" if you need more time for corrections`}>
                                        <div
                                            aria-hidden="true"
                                            style={{ display: 'flex', cursor: 'pointer' }}
                                            onClick={() => pauseUploadHistoryAdmin(historyId)}
                                        >
                                            <PauseIcon />
                                        </div>
                                    </Tooltip>
                                )}
                            </>
                        )}
                    </div>
                )}
                <Typography variant="h6" style={{ marginBottom: '5px', marginTop: '15px' }}>
                    <strong>File correction</strong>
                </Typography>
                <div style={{ marginBottom: '15px' }}>
                    <CustomButton
                        label="All"
                        onClick={() => {
                            if (currentFilter !== 'all') {
                                setCurrentFilter('all');
                                setDataList(null);
                                setCursor(null);
                                fetchUploadHistoryDataList({ filter: 'all' });
                            }
                        }}
                        style={{
                            borderBottom: currentFilter === 'all' ? '1px solid black' : '1px solid silver',
                            fontSize: '24px',
                            fontFamily: 'LVMHSans-SemiLight, sans-serif',
                            padding: '10px 16px',
                        }}
                    />
                    <CustomButton
                        label="Error correction"
                        onClick={() => {
                            if (currentFilter !== 'error') {
                                setCurrentFilter('error');
                                setDataList(null);
                                setCursor(null);
                                fetchUploadHistoryDataList({ filter: 'error' });
                            }
                        }}
                        style={{
                            borderBottom: currentFilter === 'error' ? '1px solid black' : '1px solid silver',
                            fontSize: '24px',
                            fontFamily: 'LVMHSans-SemiLight, sans-serif',
                            padding: '10px 16px',
                        }}
                    />
                </div>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <tbody>
                        {dataList?.items?.map((item) => (
                            <tr key={item.id}>
                                <td
                                    style={{
                                        border: '1px solid #B1BACE',
                                        padding: '8px',
                                        textAlign: 'left',
                                        minWidth: '80px',
                                        width: '80px',
                                    }}
                                >
                                    {item.number}
                                </td>
                                {item.cells.map((cell, cellIndex) => (
                                    <td
                                        key={cell.id}
                                        style={{
                                            border: '1px solid #B1BACE',
                                            padding: '8px',
                                            textAlign: 'right',
                                            verticalAlign: 'bottom',
                                            maxWidth: '150px',
                                            minWidth: '150px',
                                            width: '150px',
                                            background: `${
                                                cell.status === 'error'
                                                    ? 'rgba(255, 86, 48, 0.10)'
                                                    : cell.success
                                                      ? 'rgba(48, 255, 48, 0.10)'
                                                      : '#fff'
                                            }`,
                                        }}
                                    >
                                        {cell.status === 'error' && (
                                            <div
                                                style={{ cursor: 'pointer', display: 'inline-block' }}
                                                role="button"
                                                tabIndex={0}
                                                onClick={() =>
                                                    handleOpenModal(
                                                        <>
                                                            {cell.errors.length > 0 && (
                                                                <>
                                                                    <Typography
                                                                        variant="subtitle1"
                                                                        color="error"
                                                                        gutterBottom
                                                                    >
                                                                        Errors:
                                                                    </Typography>
                                                                    <Typography variant="caption" color="error">
                                                                        {cell.errors.map((message, index) => (
                                                                            <React.Fragment key={index}>
                                                                                {index > 0 && <br />}
                                                                                {message}
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </Typography>
                                                                </>
                                                            )}
                                                            {Array.isArray(schemaResource.columns[cellIndex].rules) &&
                                                                schemaResource.columns[cellIndex].rules.length > 0 && (
                                                                    <>
                                                                        <Typography
                                                                            variant="subtitle1"
                                                                            color="textPrimary"
                                                                            style={{ marginTop: '10px' }}
                                                                            gutterBottom
                                                                        >
                                                                            Rules:
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="caption"
                                                                            color="textSecondary"
                                                                        >
                                                                            {schemaResource.columns[cellIndex].rules
                                                                                .map((rule) => rule.description)
                                                                                .join(' ')}
                                                                        </Typography>
                                                                    </>
                                                                )}
                                                        </>,
                                                    )
                                                }
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter' || e.key === ' ') {
                                                        e.preventDefault();
                                                        handleOpenModal(
                                                            <>
                                                                {cell.errors.length > 0 && (
                                                                    <>
                                                                        <Typography
                                                                            variant="subtitle1"
                                                                            color="error"
                                                                            gutterBottom
                                                                        >
                                                                            Errors:
                                                                        </Typography>
                                                                        <Typography variant="caption" color="error">
                                                                            {cell.errors.map((message, index) => (
                                                                                <React.Fragment key={index}>
                                                                                    {index > 0 && <br />}
                                                                                    {message}
                                                                                </React.Fragment>
                                                                            ))}
                                                                        </Typography>
                                                                    </>
                                                                )}
                                                                {Array.isArray(
                                                                    schemaResource.columns[cellIndex].rules,
                                                                ) &&
                                                                    schemaResource.columns[cellIndex].rules.length >
                                                                        0 && (
                                                                        <>
                                                                            <Typography
                                                                                variant="subtitle1"
                                                                                color="textPrimary"
                                                                                style={{ marginTop: '10px' }}
                                                                                gutterBottom
                                                                            >
                                                                                Rules:
                                                                            </Typography>
                                                                            <Typography
                                                                                variant="caption"
                                                                                color="textSecondary"
                                                                            >
                                                                                {schemaResource.columns[cellIndex].rules
                                                                                    .map((rule) => rule.description)
                                                                                    .join(' ')}
                                                                            </Typography>
                                                                        </>
                                                                    )}
                                                            </>,
                                                        );
                                                    }
                                                }}
                                            >
                                                <Typography
                                                    variant="caption"
                                                    color="error"
                                                    style={{ textDecoration: 'underline', textUnderlineOffset: '3px' }}
                                                >
                                                    Details
                                                </Typography>
                                                <IconButton style={{ padding: 0, marginLeft: '5px' }}>
                                                    <InfoIcon color="error" />
                                                </IconButton>
                                            </div>
                                        )}
                                        <CustomTextField
                                            key={cell.id}
                                            value={cell.value || ''}
                                            onChange={(event) => handleChange(event, cell.id, cell.errors.length)}
                                            status={
                                                cell.status === 'error'
                                                    ? 'error'
                                                    : cell.loading
                                                      ? 'loading'
                                                      : cell.success
                                                        ? 'success'
                                                        : null
                                            }
                                            style={{ minWidth: '100px' }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {cell.loading && <CircularProgress size={24} />}
                                                        {!cell.loading && cell.success && (
                                                            <IconButton edge="end" disableRipple>
                                                                <CheckCircleIcon color="success" />
                                                            </IconButton>
                                                        )}
                                                        {!cell.loading && cell.status === 'error' && (
                                                            <IconButton edge="end" disableRipple>
                                                                <HighlightOffIcon color="error" />
                                                            </IconButton>
                                                        )}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>
        );
    };

    return (
        <div style={{ padding: '25px' }}>
            <Dialog open={openModal} onClose={handleCloseModal}>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseModal}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <div style={{ padding: '20px', backgroundColor: 'white', margin: '20px auto', maxWidth: '400px' }}>
                    <Typography variant="body1">{modalContent}</Typography>
                </div>
            </Dialog>
            {configuration && (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item md={8}>
                                    <Typography
                                        variant="h2"
                                        style={{ marginBottom: '10px', fontFamily: 'LVMHSans-SemiLight, sans-serif' }}
                                    >
                                        {configuration.details.displayName?.value || 'N/A'}
                                    </Typography>
                                    <Typography variant="body2">
                                        <strong style={{ marginRight: '5px' }}>Errors #:</strong>{' '}
                                        {configuration?.errorsNumber || 0}
                                    </Typography>
                                </Grid>
                                <Grid item md={4}>
                                    <div style={{ marginTop: '25px', marginBottom: '15px' }}>
                                        <Typography
                                            variant="h4"
                                            style={{ fontFamily: 'LVMHSans-SemiLight, sans-serif' }}
                                        >
                                            Configuration details:
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography variant="body2" style={{ marginBottom: '10px' }}>
                                            <strong style={{ marginRight: '5px' }}>Configuration Owner:</strong>{' '}
                                            {configuration.details.ownerEmail || 'N/A'}
                                        </Typography>
                                        <Typography variant="body2" style={{ marginBottom: '10px' }}>
                                            <strong style={{ marginRight: '5px' }}>Technical name:</strong>{' '}
                                            {configuration.details.technicalName?.value || 'N/A'}
                                        </Typography>
                                        <Typography variant="body2" style={{ marginBottom: '10px' }}>
                                            <strong style={{ marginRight: '5px' }}>Description:</strong>{' '}
                                            {configuration.details.description?.value || 'N/A'}
                                        </Typography>
                                        <Typography variant="body2" style={{ marginBottom: '10px' }}>
                                            <strong style={{ marginRight: '5px' }}>Category:</strong>{' '}
                                            {configuration.details.category?.value || 'N/A'}
                                        </Typography>
                                        <Typography variant="body2" style={{ marginBottom: '10px' }}>
                                            <strong style={{ marginRight: '5px' }}>FileFormat:</strong>{' '}
                                            {configuration.details.fileFormat?.value || 'N/A'}
                                        </Typography>
                                        <Typography variant="body2" style={{ marginBottom: '10px' }}>
                                            <strong style={{ marginRight: '5px' }}>Sending:</strong>{' '}
                                            {configuration?.sendingDataToBQ?.type || 'N/A'}
                                        </Typography>
                                        <Typography variant="body2" style={{ marginBottom: '10px' }}>
                                            <strong style={{ marginRight: '5px' }}>Version:</strong>{' '}
                                            {configuration.details.version?.value || 'N/A'}
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Paper style={{ marginTop: '25px' }}>
                        <div style={{ padding: '25px' }}>
                            <div style={{ marginTop: '10px' }}>
                                {configuration?.validationErrors?.length ? (
                                    <>
                                        <Typography variant="h5">Validation Errors:</Typography>
                                        {configuration?.validationErrors.map((error, index) => (
                                            <Accordion key={index}>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                    <Typography style={{ fontFamily: 'sans-serif' }}>
                                                        <strong>Property Path:</strong> {error.propertyPath}
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography style={{ fontFamily: 'sans-serif' }}>
                                                        <strong>Message:</strong> {error.message}
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))}
                                    </>
                                ) : (
                                    ''
                                )}
                                <Typography variant="h5" style={{ marginBottom: '15px' }}>
                                    Errors report:
                                </Typography>
                                <div style={{ overflowX: 'auto' }}>{renderTable()}</div>
                            </div>
                            {dataList &&
                                currentItems.length > 0 &&
                                currentItems.length === dataList.numItemsPerPage && (
                                    <div>
                                        <Button
                                            onClick={() =>
                                                fetchUploadHistoryDataList({ cursor, filter: currentFilter })
                                            }
                                        >
                                            Load More
                                        </Button>
                                    </div>
                                )}
                            {configuration?.sendingDataToBQ?.type === 'manual' && configuration?.status === 'done' && (
                                <div>
                                    <CustomButton label="Upload" onClick={() => fetchUploadHistoryManuallySend()} />
                                </div>
                            )}
                            <div>
                                <Link to={`/configuration/${id}/my-uploads/`}>
                                    <CustomButton label="Back to uploads list" />
                                </Link>
                            </div>
                        </div>
                    </Paper>
                </>
            )}
        </div>
    );
};

export default ViewDataConfigurationAdmin;
