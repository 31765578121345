import React, { ReactNode, useEffect, useRef } from 'react';
import { Box, CssBaseline, Grid } from '@mui/material';
import Navbar from '@/components/navbar';
import './page.scoped.scss';
import { useTranslation } from 'react-i18next';
import AccountMenu from '@/components/account-menu';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store';
import { getUser, setBusinessUserList, setUser } from '@/store/usersSlice';
import { useUsersApi } from '@/api-client/users-api';
import { useBusinessTeamsApi } from '@/api-client/business-teams-api';
import { setBusinessTeamsFullList } from '@/store/businessTeamsSlice';
import { useOktaAuth } from '@okta/okta-react';
import { useConfigurationsApi } from '@/api-client/configurations-api';
import { setConfigurationsCategoriesFullList } from '@/store/configurationsSlice';
import { useSnackbar } from 'notistack';
import { AVAILABLE_ROLES } from '@/types';
import { useBusinessUserCategoryApi } from '@/api-client/business-user-category-api';
import { useBusinessUserApi } from '@/api-client/business-user-user-api';
import { Link } from 'react-router-dom';

interface PageProps {
    children: ReactNode;
}

const HEADER_HEIGHT = 233;
const SIDEBAR_WIDTH = 305;

const Page: React.FC<PageProps> = ({ children }: PageProps) => {
    const { t } = useTranslation();
    const user = useSelector((state: RootState) => getUser(state));
    const dispatch: AppDispatch = useDispatch();
    const { getUserInfo } = useUsersApi();
    const { getBusinessTeamsList } = useBusinessTeamsApi();
    const { getConfigurationsCategoriesList } = useConfigurationsApi();
    const { getBusinessUserCategoriesList } = useBusinessUserCategoryApi();
    const { getBusinessUserUsersList } = useBusinessUserApi();
    const { authState } = useOktaAuth();
    const { enqueueSnackbar } = useSnackbar();
    const { oktaAuth } = useOktaAuth();

    const hasFetchedRef = useRef(false);

    const fetchUserInfo = async (): Promise<void> => {
        try {
            const info = await getUserInfo();
            dispatch(setUser(info));
        } catch (error) {
            enqueueSnackbar(`${error?.title}`, { variant: 'error', preventDuplicate: true });
            console.error('Error fetching user info:', error);
            if (error.type === 'authentication_error') {
                setTimeout(() => {
                    oktaAuth.signOut();
                }, 2000);
            }
        }
    };

    const fetchBusinessTeamsList = async (): Promise<void> => {
        try {
            const list = await getBusinessTeamsList({ perPage: 1000 });
            dispatch(setBusinessTeamsFullList(list));
        } catch (error) {
            enqueueSnackbar(`${error?.title}`, { variant: 'error', preventDuplicate: true });
            console.error('Error fetching business teams list:', error);
        }
    };

    const fetchConfigurationsCategoriesList = async (): Promise<void> => {
        try {
            const list = await getConfigurationsCategoriesList({ perPage: 1000 });
            dispatch(setConfigurationsCategoriesFullList(list));
        } catch (error) {
            enqueueSnackbar(`${error?.title}`, { variant: 'error', preventDuplicate: true });
            console.error('Error fetching business teams list:', error);
        }
    };

    const fetchBusinessUserCategoriesList = async (): Promise<void> => {
        try {
            const list = await getBusinessUserCategoriesList({ perPage: 1000 });
            dispatch(setConfigurationsCategoriesFullList(list));
        } catch (error) {
            enqueueSnackbar(`${error?.title}`, { variant: 'error', preventDuplicate: true });
            console.error('Error fetching business teams list:', error);
        }
    };

    const fetchBusinessUserUsersList = async (): Promise<void> => {
        try {
            const list = await getBusinessUserUsersList();
            dispatch(setBusinessUserList(list));
        } catch (error) {
            enqueueSnackbar(`${error?.title}`, { variant: 'error', preventDuplicate: true });
            console.error('Error fetching business teams list:', error);
        }
    };

    useEffect(() => {
        if (!authState || !authState.isAuthenticated || hasFetchedRef.current) {
            return;
        }
        fetchUserInfo();
        hasFetchedRef.current = true;
    }, [authState]);

    useEffect(() => {
        if (user.role) {
            if (user.role !== AVAILABLE_ROLES.ROLE_USER) {
                fetchBusinessTeamsList();
                fetchConfigurationsCategoriesList();
            } else {
                fetchBusinessUserCategoriesList();
                fetchBusinessUserUsersList();
            }
        }
    }, [user.role]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100%' }}>
            <CssBaseline />
            <Grid container direction="row" sx={{ flex: '1 1 auto', height: `calc(100vh - ${HEADER_HEIGHT}px)` }}>
                <Grid item sx={{ width: SIDEBAR_WIDTH, height: '100%', overflow: 'auto', background: '#fff' }}>
                    <Box sx={{ p: '45px' }}>
                        <Link to={'/home'} style={{ textDecoration: 'none' }}>
                            <p className="h1" style={{ fontSize: '50px' }}>
                                {t('page.header.title')}
                            </p>
                        </Link>
                        <Navbar />
                    </Box>
                </Grid>
                <Grid item xs sx={{ height: '100%', overflow: 'auto', backgroundColor: '#F1F1F4' }}>
                    <Grid
                        container
                        direction="column"
                        sx={{ flex: '0 1 auto', borderBottom: '1px solid #F1F1F4', backgroundColor: '#FFF' }}
                    >
                        <Grid item xs={12} sx={{ height: HEADER_HEIGHT }}>
                            {user.email ? <AccountMenu /> : ''}
                        </Grid>
                    </Grid>
                    {children}
                </Grid>
            </Grid>
            {/*<Grid container direction="column" sx={{ flex: '0 1 auto' }}>*/}
            {/*  <Grid item xs={12} sx={{ bgcolor: 'primary.main' }}>*/}
            {/*    <Toolbar>*/}
            {/*      <Typography variant="h6" noWrap component="div">*/}
            {/*        Footer*/}
            {/*      </Typography>*/}
            {/*    </Toolbar>*/}
            {/*  </Grid>*/}
            {/*</Grid>*/}
        </Box>
    );
};

export default Page;
